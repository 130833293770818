.modal {
	min-width: 496px;
	min-height: 396px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.scroller {
	height: 370px;
}

.requisiteHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 36px;
	margin-bottom: 16px;
}

.requisiteTitle {
	color: var(--Blue_gray-90, #21272a);

	/* Base/14-20-sb */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 142.857% */
}

.addBtn {
	margin-bottom: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;

	color: var(--Cyan-70, #0072c3);
	text-align: center;

	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
}

.btn {
	border: none;
	background-color: transparent;
}

.submitBtn {
	display: none;
}